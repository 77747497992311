import api from "@/services/apiService"

/**
 * ตั้งค่าสมาชิก
 */
class AccountSettingService {

  getAccountSettingGroup(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/group' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingGroup(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/group', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountSettingPayout(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/payout' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingPayout(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/payout', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingPayouts(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/payouts', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountSettingBet(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/bet' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingBet(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/bet', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingBets(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/bets', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountSettingSharing(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/share' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingShare(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/share', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingShares(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'account-setting/shares', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountSettingSets(accountId=null) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/sets' + (accountId?(`/${accountId}`):''))
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSetsRate(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_API_MAIN + 'account-setting/sets-rate', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingSets(data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_API_MAIN + 'account-setting/sets', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountSettingMarkets(accountId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/market/' + accountId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateAccountSettingMarkets(accountId, data) {
    return new Promise((resolve, reject) => {
      api.put(process.env.VUE_APP_API_MAIN + 'account-setting/market/' + accountId, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  getAccountHoldings(accountId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account-setting/holding/' + accountId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }
}

export default new AccountSettingService()
